.spark-video {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
}

.spark-video--fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
}

.spark-video--idle {
  cursor: none;
}

.spark-video video {
  position: relative;
  z-index: 0;
  display: block;
  width: 100%;
  -webkit-transition: 160ms ease-out;
          transition: 160ms ease-out;
}

.spark-video--buffering video {
  opacity: .8;
}

.spark-video button {
  outline: 0;
}

/* Controls */

.spark-video__controls {
  position: relative;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  overflow: hidden;
  width: 100%;
  height: 32px;
  margin: 0 auto;
  -webkit-transition: opacity 250ms ease-out 300ms;
          transition: opacity 250ms ease-out 300ms;
  background-color: rgb(34, 34, 34);
}

.spark-video--no-flexbox .spark-video__controls > * {
  float: left;
}

.spark-video--overlay .spark-video__controls {
  position: absolute;
  background-color: rgba(34, 34, 34, .7);
}

.spark-video--idle .spark-video__controls,
.spark-video--overlay.spark-video--playing .spark-video__controls {
  opacity: 0;
}

.spark-video--overlay.spark-video:hover .spark-video__controls {
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms;
  opacity: 1;
}

.spark-video--on-top .spark-video__controls {
  bottom: 32px;
  width: 70%;
}

.spark-video--fullscreen .spark-video__controls {
  position: absolute;
}

.spark-video__controls--stacked {
  overflow: visible;
}

.spark-video__play-toggle,
.spark-video__volume,
.spark-video__fullscreen {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
          flex-shrink: 0;
  cursor: pointer;
  border: 0;
  background: none;
}

.spark-video__play-toggle:focus,
.spark-video__volume:focus,
.spark-video__fullscreen:focus {
  box-shadow: inset 0 0 0 2px rgba(27, 127, 204, .8);
}

.spark-video__play-control,
.spark-video--playing .spark-video__pause-control,
.spark-video__mute-control,
.spark-video--muted .spark-video__unmute-control,
.spark-video__fullscreen-control,
.spark-video--fullscreen .spark-video__exit-fullscreen-control {
  display: block;
}

.spark-video__controls--hidden,
.spark-video__pause-control,
.spark-video--playing .spark-video__play-control,
.spark-video__unmute-control,
.spark-video--muted .spark-video__mute-control,
.spark-video__exit-fullscreen-control,
.spark-video--fullscreen .spark-video__fullscreen-control {
  display: none;
}

.spark-video__flexible-space {
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.spark-video__progress-container {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 12px 4px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

/* The only browser w/o flexbox support which SparkVideo supports is IE9 */
.spark-video--no-flexbox .spark-video__flexible-space,
.spark-video--no-flexbox .spark-video__progress-container {
  /* 3 32px buttons and ~54px time */
  width: calc(100% - 150px);
}

.spark-video__controls--stacked .spark-video__progress-container {
  position: absolute;
  bottom: 100%;
  width: 100%;
  height: 2px;
  padding: 0;
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  -webkit-transition: 200ms ease-out;
          transition: 200ms ease-out;
  background-color: rgb(34, 34, 34);
}

.spark-video:hover .spark-video__controls--stacked .spark-video__progress-container {
  height: 10px;
}

.spark-video--overlay .spark-video__controls--stacked .spark-video__progress-container {
  background-color: rgba(34, 34, 34, .7);
}

.spark-video__progress-holder {
  position: relative;
  height: 100%;
}

.spark-video__buffer,
.spark-video__progress {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgb(77, 77, 77);
}

.spark-video__progress {
  z-index: 2;
  background-color: rgb(191, 191, 191);
}

.spark-video__current-time {
  display: block;
  width: auto;
  height: 32px;
  padding: 0;
  padding: 0 8px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
          flex-shrink: 0;
  color: rgb(224, 224, 224);
  font-size: .9375em;
  line-height: 32px;
}

/* Icons */

.spark-video__play-control {
  width: 0;
  height: 0;
  margin-left: 9px;
  border-width: 8px 0 8px 14px;
  border-style: solid none solid solid;
  border-color: transparent transparent transparent rgb(224, 224, 224);
}

.spark-video__play-toggle:hover .spark-video__play-control {
  border-left-color: rgb(255, 255, 255);
}

.spark-video__pause-control {
  position: absolute;
  top: 8px;
  left: 9px;
  width: 5px;
  height: 16px;
  margin: 0;
  background-color: rgb(224, 224, 224);
  box-shadow: 8px 0 0 0 rgb(224, 224, 224);
}

.spark-video__play-toggle:hover .spark-video__pause-control {
  background-color: rgb(255, 255, 255);
  box-shadow: 8px 0 0 0 rgb(255, 255, 255);
}

.spark-video__volume svg,
.spark-video__fullscreen svg {
  position: absolute;
  top: 8px;
  left: 8px;
  display: block;
  width: 16px;
  height: 16px;
  margin: 0;
  fill: rgb(224, 224, 224);
}

.spark-video__volume:hover svg,
.spark-video__fullscreen:hover svg {
  fill: rgb(255, 255, 255);
}
